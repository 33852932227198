import HunnyPlayLogo from "./images/logo-text.png";
import NetworkLogo from "./svgs/network.svg";
import TelegramIcon from "./svgs/telegram.svg";
import TwitterIcon from "./svgs/twitter.svg";
import MediumIcon from "./svgs/medium.svg";
import DiscordIcon from "./svgs/discord.svg";

import "./App.css";

function App() {
  return (
    <main>
      <div className="mirrors">
        <div className="mirrors__logo-link">
          <a href="https://hunnyplay.io" target={"_blank"}>
            <picture className="logo__picture">
              <img src={HunnyPlayLogo} className="logo__picture" alt="logo" />
            </picture>
          </a>
        </div>

        <main className="mirrors__content">
          <div className="mirrors__content-left">
            <h1 className="mirrors__content-title">
              <span>HunnyPlay.com</span>
              Official Mirror Sites
            </h1>

            <p className="mirrors__content-subtitle">Have any issues connecting to HunnyPlay? Use one of our mirror sites</p>
            <aside className="mirrors__aside-desktop">
              <h6 className="mirrors_aside-tittle">What is mirrors site?</h6>
              <p className="mirrors__aside-description">
                A mirror site is an exact duplicate website that is stored on a different web server. It is used to balance the traffic
                load. HunnyPlay's mirror websites are absolute copies of the official site created to enable access for players, who have
                issues with connecting to HunnyPlay.
              </p>
            </aside>

            <div className="footer-social-icons-desktop">
              <a target={"_blank"} className="footer-social-icon__icons telegram" href="https://t.me/Hunnyplay">
                <img src={TelegramIcon} className="footer-social-icons__svg" />
              </a>
              <a target={"_blank"} className="footer-social-icon__icons twitter" href="https://twitter.com/hunnyplay_">
                <img src={TwitterIcon} className="footer-social-icons__svg" />
              </a>
              <a target={"_blank"} className="footer-social-icon__icons medium" href="https://medium.com/hunnyfinance">
                <img src={MediumIcon} className="footer-social-icons__svg" />
              </a>
              <a target={"_blank"} className="footer-social-icon__icons discord" href="https://discord.com/invite/hunnyplay">
                <img src={DiscordIcon} className="footer-social-icons__svg" />
              </a>
            </div>
          </div>

          <div className="domains desktop">
            <ul className="domains__list-domains">
              <li className="domains__list-title">
                <span>Domain</span>
                <span>Status</span>
              </li>

              <li className="domains__list-item">
                <div className="domains__list-item-left">
                  <img src={NetworkLogo} className="domains__list-item-img" />
                  <a target={"_blank"} rel="noopener noreferrer" className="domains__list-item-link" href="https://hunnyplay.io">
                    hunnyplay.io
                  </a>
                </div>
                <div className="domains__list-item-online">
                  <img
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAJCAYAAAAGuM1UAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAC4SURBVHgBjY4xCsIwFIbfSzoILhlFHHoEvUBJF8XNm4gnsJ5Ab+IourQ6uNojdFEcXUREXmMeojTF0v5DSPJ/H+8hNEhvF0xNbvr3h5x5dXB3G8wtHPG93aK919loXwhaAmB6GR0WZRjNB7bJCGQiJJBGAxMuGKiEjQyv4yRDP9bq9aST/fS5MYiRQLjZNVZlmB/IB68lkeKvVIgD/4S/kp1CuRwUYUdwJAQFSOF5eEyhSdRaq6ruDarKVlnRGyz8AAAAAElFTkSuQmCC"
                    className="domains__list-item-online-check"
                  />
                  Online
                </div>
              </li>
            </ul>
          </div>

          <aside className="mirrors__aside-mobile">
            <h6 className="mirrors_aside-tittle">What is mirrors site?</h6>
            <p className="mirrors__aside-description">
              A mirror site is an exact duplicate website that is stored on a different web server. It is used to balance the traffic load.
              HunnyPlay's mirror websites are absolute copies of the official site created to enable access for players, who have issues
              with connecting to HunnyPlay.
            </p>
          </aside>

          <div className="footer-social-icons-mobile">
            <a target={"_blank"} className="footer-social-icon__icons telegram" href="https://t.me/Hunnyplay">
              <img src={TelegramIcon} className="footer-social-icons__svg" />
            </a>
            <a target={"_blank"} className="footer-social-icon__icons twitter" href="https://twitter.com/hunnyplay_">
              <img src={TwitterIcon} className="footer-social-icons__svg" />
            </a>
            <a target={"_blank"} className="footer-social-icon__icons medium" href="https://medium.com/hunnyfinance">
              <img src={MediumIcon} className="footer-social-icons__svg" />
            </a>
            <a target={"_blank"} className="footer-social-icon__icons discord" href="https://discord.com/invite/hunnyplay">
              <img src={DiscordIcon} className="footer-social-icons__svg" />
            </a>
          </div>
        </main>
      </div>
    </main>
  );
}

export default App;
